export default {
  core: {
    locales: {
      ru: {
        header: 'Russian'
      },
      en: {
        header: 'English'
      },
    },

    themes: {
      dark: {
        header: 'Dark'
      },
      light: {
        header: 'Light'
      }
    },

    common: {
      noName: '<no name>'
    },

    commands: {
      navigateMain: {
        header: 'Back to desktops'
      },
      navigateAdmin: {
        header: 'Admin panel'
      },
      logout: {
        header: 'Logout'
      },
      version: {
        header: 'Version'
      }
    },

    dialog: {
      commands: {
        ok: {
          header: 'OK'
        },
        cancel: {
          header: 'Cancel'
        },
        yes: {
          header: 'Yes'
        },
        no: {
          header: 'No'
        }
      }
    },

    tables: {
      noData: 'No data',
      loading: 'Loading...',
      loadingError: 'Error loading data',
      notEnoughRights: "Insufficient rights to view",
      commands: {
        filter: {
          header: 'Filter'
        }
      }
    },

    paginations: {
      total: 'Total: {0}'
    },

    errors: {
      fields: {
        email: 'Invalid email address entered',
        ipaddress: 'Field is not an IP-address',
        login: 'Latin letters, numbers (5-12 characters). No spaces or special characters',
        number: 'Field is not a number',
        password: 'Password must be at least 8 characters long and contain at least one number and one capital letter',
        passport: 'Field is not a passport number',
        phone: 'Invalid phone number entered',
        regex: 'Field does not match given expression',
        require: 'Field cannot be empty',
        unique: 'A record with this data already exists'  
      }
    }
  }
}
