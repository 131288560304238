export default {
  'wp-pass-office': {
    name: 'Бюро пропусков',
    inputs: {
      refresh: 'Источник события',
    },
    settings: {
      orderTypes: "Типы заявок",
      orderType: "Тип заявок",
      addOrderType: "Добавить тип заявок",
      editOrderType: "Изменить тип заявок",
      removeOrderType: "Удалить тип заявок",
      orderObjects: "Объекты заявок",
    },
    tabs: {
      persons: 'Персоны',
      vehicles: 'Транспорт',
      cards: 'Карты',
      permits: 'Права',
      violations: 'Нарушения',
    },
    labels: {
      orders: "Заявки",
      createPassBtn: "Новое право",
      createSubject: "Создание субъекта",
      createPermit: "Создание права",
      createCard: "Создание карты",
      createViolation: "Создание нарушения",
      editSubject: "Редактирование субъекта",
      updateSubject: "Обновление субъекта",
      editPermit: "Редактирование права",
      editCard: "Редактирование карты",
      editViolation: "Редактирование нарушения",
      name: "Название",
      createCardBtn: "Создать карту",
      save: 'Сохранить',
      update: 'Обновить',
      filters: {
        organizationList: 'Организации',
        securityList: 'Список',
        violations: 'Нарушения',
        actions: {
          apply: 'Применить',
          clear: 'Очистить',
        }
      },
      card: {
        title: 'Карта',
        topLabel: 'Информация',
        cardType: 'Тип карты',
        format: 'Формат',
        status: 'Статус',
        permits: "Права",
        code: 'Код карты',
        remove: {
          title: 'Удалить карту',
          confirm: 'Вы действительно хотите удалить карту?',
        },
      },
      permit: {
        title: 'Право',
        name: "Название",
        topLabel: 'Информация',
        levelPermit: 'Уровни доступа',
        remove: {
          title: 'Удалить право',
          confirm: 'Вы действительно хотите удалить право?',
        },
      },
      violation: {
        topLabel: 'Информация',
        violationTime: {
          date: 'Дата нарушения',
          time: 'Время нарушения',
        },
        violationType: 'Тип нарушения',
        name: 'Автор',
        note: 'Комментарий',
      },
      personInformation: {
        topLabel: "Информация",
        name: "Информация о персоне",
        fullName: "ФИО",
        firstName: "Имя",
        middleName: "Отчество",
        lastName: "Фамилия",
        birthDate: "Дата рождения",
        subject: "Субъект",
        status: "Статус",
        attachPhoto: "Прикрепите фото",
        organization: "Организация",
        position: "Должность",
        securityList: "Список",
        documentLabel: "Документ",
        citizenship: "Гражданство",
        document: {
          type: "Тип документа",
          seriesDocument: 'Серия',
          numberDocument: 'Номер',
          dateDocument: 'Дата выдачи',
          issuedDocument: 'Код подразделения',
          issuedPlace: '​Учреждение, выдавшее документ',
        },
      },
      vehicleInformation: {
        topLabel: "Информация",
        name: "Информация о ТС",
        licensePlate: "Гос. номер",
        securityList: "Список",
        brand: "Марка ТС",
        color: "Цвет ТС",
        type: "Тип ТС",
        subject: "Субъект",
        status: "Статус",
        inspect: "Пропуск с досмотром",
        attachPhoto: "Прикрепите фото",
        organization: "Организация",
        document: {
          type: "Тип документа",
          seriesDocument: 'Серия',
          numberDocument: 'Номер',
          dateDocument: 'Дата выдачи',
          issuedDocument: 'Код подразделения',
          issuedPlace: '​Учреждение, выдавшее документ',
        },
      },
      orderFilter: {
        datePeriodPass: 'Срок действия',
        from: 'с',
        to: 'по',
        format: 'DD.MM.YYYY',
      },
    },
    filters: {
      violations: {
        haveViolations: {
          text: 'Есть нарушения',
          value: 'open_violation_count__gt__0',  
        },
        noViolations: {
          text: 'Нет нарушений',
          value: 'open_violation_count__eq__0',
        }
      },
    },
    orderList: {
      title: 'Заявки',
      headers: {
        number: {
          text: 'Номер',
          sortable: false,
        },
        objects: {
          text: 'Объекты',
          sortable: false,
        },
        requester: {
          text: 'Заявитель',
          sortable: false,
        },
        organization: {
          text: 'Организация',
          sortable: false,
        },
        orderType: {
          text: 'Тип заявки',
          sortable: false,
        },
        actions: {
          sortable: false,
        },
      },
      searchPanel: {
        search: 'Поиск',
      },
      listItem: {
        viewTip: 'Просмотр заявки',
        createTip: 'Создать пропуск',
      }
    },
    searchPanel: {
      search: 'Поиск',
    },
    actions: {
      saveButton: 'Сохранить',
    },
    preferences: {
      dateFormat: 'DD.MM.YYYY',
      timeFormat: 'HH:mm',
    },
    listItem: {
      headers: {
        name: {
          text: 'Субъект',
          sortable: true,
        },
        ref_organization: {
          text: 'Организация',
          sortable: true,
        },
        ref_security_list: {
          text: 'Список',
          sortable: true,
        },
        active_cards_count: {
          text: 'Карта',
          sortable: true,
        },
        open_violation_count: {
          text: 'Нарушения',
          sortable: true,
        },
        actions: {
          text: '',
          sortable: false,
        },
      },
      remove: {
        title: 'Удалить субъект',
        confirm: 'Вы действительно хотите удалить субъект?',
      },
      removeActive: {
        title: 'Удалить субъект',
        confirm: 'Вы действительно хотите удалить субъект? У субъекта есть активный пропуск.',
      },
      editTip: 'Редактировать субъект',
      removeTip: 'Вы уверены, что хотите удалить субъект?',
      actionsTips: {
        removeItem: 'Удалить {0}',
      },
    },
    newObjectList: {
      headers: {
        name: {
          text: 'Субъект',
          sortable: true,
        },
        organization: {
          text: 'Организация',
          sortable: false,
        },
        ready: {
          text: 'Готов',
          sortable: false,
        },
      },
    },
    dialogList: {
      headers: {
        name: {
          text: 'Субъект',
          sortable: true,
        },
        organization: {
          text: 'Организация',
          sortable: false,
        },
        ref_security_list: {
          text: 'Список',
          sortable: true,
        },
        active_cards_count: {
          text: 'Карта',
          sortable: true,
        },
      },
    },
    cardItem: {
      headers: {
        ref_card_type: {
          text: 'Тип карты',
          sortable: true,
        },
        code: {
          text: 'Код карты',
          sortable: true,
        },
        ref_format: {
          text: 'Формат',
          sortable: true,
        },
        ref_status: {
          text: 'Статус',
          sortable: false,
        },
        actions: {
          sortable: false,
        }
      },
      createBtn: 'Создать карту',
      permitList: 'Права доступа',
      removeTip: 'Вы уверены, что хотите удалить карту?',
      actionsTips: {
        removeItem: 'Удалить {0}',
      },
    },
    permitItem: {
      headers: {
        name: {
          text: 'Название',
          sortable: true,
        },
        validity: {
          text: 'Срок действия',
          sortable: false,
        },
        levelPermit: {
          text: 'Уровни доступа',
          sortable: false,
        },     
        actions: {
          sortable: false,
        },
      },
      remove: {
        title: 'Удалить право',
        confirm: 'Вы действительно хотите удалить право?',
      },
      removeActive: {
        title: 'Удалить право',
        confirm: 'Вы действительно хотите удалить активный право?',
      },
      levelPermit: "Уровни доступа",
      createBtn: "Создать право",
      removeTip: 'Вы уверены, что хотите удалить право?',
      actionsTips: {
        removeItem: 'Удалить {0}',
      },
      format: 'DD.MM.YYYY',
    },
    violationItem: {
      headers: {
        type: {
          text: 'Тип',
          sortable: false,
        },
        violation_time: {
          text: 'Время',
          sortable: true,
        },
        status: {
          text: 'Статус',
          sortable: false,
        },
        actions: {
          sortable: false,
        }
      },
      open: 'Открыто',
      closed: 'Закрыто',
      close: {
        title: 'Закрыть нарушение',
        confirm: 'Вы действительно хотите закрыть нарушение?',
      },
      createBtn: 'Создать нарушение',
      closeTip: 'Вы действительно хотите закрыть нарушение?',
      actionsTips: {
        closeViolation: 'Закрыть нарушение {0}',
      },
      timeFormat: 'DD.MM.YYYY HH:mm',
    },
  },
};
