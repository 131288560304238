import PermitPersonCardTranslation from './en/permit-person-card'
import PermitVehicleCardTranslation from './en/permit-vehicle-card'
import ControllerBoardTranslation from './en/controller-board'
import VideoStreamTranslation from './en/video-stream'
import VerificationPanelTranslation from './en/verification-panel'
import CommonTranslation from './en/common'
import PassageAccessQueueTranslation from './en/passage-access-queue'
import CardCardTraslation from './en/card-card'
import CheckPointCardTraslation from './en/check-point-card'
import LevelPermitCardTraslation from './en/level-permit-card'
import PassOfficeTraslation from './en/pass-office'

import ComponentsLocales from '../components/locales/en'
import ErrorMessagesLocale from "./en/error-messages";

export default Object.assign({},
  PermitPersonCardTranslation,
  PermitVehicleCardTranslation,
  ControllerBoardTranslation,
  VideoStreamTranslation,
  VerificationPanelTranslation,
  CommonTranslation,
  ComponentsLocales,
  PassageAccessQueueTranslation,
  CardCardTraslation,
  CheckPointCardTraslation,
  LevelPermitCardTraslation,
  ErrorMessagesLocale,
  PassOfficeTraslation
)
