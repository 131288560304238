export default {
  "wp-pass-office": {
    name: "Pass office",
    inputs: {
      refresh: "Event source",
    },
    settings: {
      orderTypes: "Order types",
      orderType: "Order type",
      addOrderType: "Add order type",
      editOrderType: "Edit order type",
      removeOrderType: "Remove order type",
      orderObjects: "Order objects",
    },
    tabs: {
      persons: "Persons",
      vehicles: "Vehicles",
      cards: "Cards",
      permits: 'Permits',
      violations: "Violations",
    },
    labels: {
      orders: "Orders",
      createPassBtn: "New permit",
      createSubject: "Create subject",
      createPermit: "Create permit",
      createCard: "Create card",
      createViolation: "Create violation",
      editSubject: "Edit subject",
      updateSubject: "Update subject",
      editPermit: "Edit permit",
      editCard: "Edit card",
      editViolation: "Edit violation",
      name: "Full name",
      createCardBtn: "Create card",
      save: 'Save',
      update: 'Update',
      filters: {
        organizationList: "Organization list",
        securityList: "Security list",
        violations: "Violations",
        actions: {
          apply: "Apply",
          clear: "Clear",
        }
      },
      card: {
        title: "Card",
        topLabel: "Information",
        cardType: "Card type",
        format: "Format",
        status: "Status",
        permits: "Permits",
        code: "Code",
        remove: {
          title: 'Remove card',
          confirm: 'Are you sure you want to delete card?',
        },
      },
      permit: {
        title: "Permit",
        name: "Name",
        topLabel: "Information",
        levelPermit: "Level permits",
        remove: {
          title: 'Remove card',
          confirm: 'Are you sure you want to delete card?',
        },
      },
      violation: {
        topLabel: "Information",
        violationTime: {
          date: "Violation date",
          time: "Violation time",
        },
        violationType: "Violation type",
        name: "Author",
        note: "Comment",
      },
      personInformation: {
        topLabel: "Information",
        fullName: "Full name",
        name: "Name",
        firstName: "First name",
        middleName: "Middle name",
        lastName: "Last name",
        birthDate: "Birth date",
        subject: "Subject",
        attachPhoto: "Attach photo",
        organization: "Organization",
        position: "Position",
        securityList: "Security list",
        documentLabel: 'Document',
        citizenship: "Citizenship",
        document: {
          type: "Document type",
          seriesDocument: 'Series',
          numberDocument: 'Number',
          dateDocument: 'Issue date',
          issuedDocument: 'Department code',
          issuedPlace: 'Issued departament',
        },
      },
      vehicleInformation: {
        topLabel: "Information",
        name: "Information about the vehicle",
        licensePlate: "License plate",
        securityList: "Security list",
        brand: "Vehicle brand",
        color: "Vehicle color",
        type: "Vehicle type",
        subject: "Subject",
        inspect: "Permit with inspection",
        attachPhoto: "Attach photo",
        organization: "Organization",
        document: {
          type: "Document type",
          seriesDocument: 'Series',
          numberDocument: 'Number',
          dateDocument: 'Issue date',
          issuedDocument: 'Department code',
          issuedPlace: 'Issued departament',
        },
      },
      orderFilter: {
        datePeriodPass: "Date period pass",
        from: "from",
        to: "to",
        format: "MM.DD.YYYY",
      },
    },
    filters: {
      violations: {
        haveViolations: {
          text: "There are violations",
          value: 'open_violation_count__gt__0', 
        },
        noViolations: {
          text: "No violations",
          value: 'open_violation_count__eq__0',
        }
      },
    },
    orderList: {
      title: "Orders",
      headers: {
        number: {
          text: "Number",
          sortable: false,
        },
        objects: {
          text: "Objects",
          sortable: false,
        },
        requester: {
          text: "Requester",
          sortable: false,
        },
        organization: {
          text: "Organization",
          sortable: false,
        },
        orderType: {
          text: "Order type",
          sortable: false,
        },
        actions: {
          sortable: false,
        },
      },
      searchPanel: {
        search: "Search",
      },
      listItem: {
        viewTip: "View order",
        createTip: "Create pass",
      }
    },
    searchPanel: {
      search: "Search",
    },
    actions: {
      saveButton: "Save",
    },
    preferences: {
      dateFormat: "YYYY-DD-MM",
      timeFormat: 'HH:mm',
    },
    listItem: {
      headers: {
        name: {
          text: "Name",
          sortable: true,
        },
        ref_organization: {
          text: "Organization",
          sortable: true,
        },
        ref_security_list: {
          text: "Security list",
          sortable: true,
        },
        active_cards_count: {
          text: "Card",
          sortable: true,
        },
        open_violation_count: {
          text: "Violations",
          sortable: true,
        },
        actions: {
          text: "",
          sortable: false,
        },
      },
      remove: {
        title: 'Remove subject',
        confirm: 'Are you sure you want to delete subject?',
      },
      removeActive: {
        title: 'Remove subject',
        confirm: 'Are you sure you want to delete subject? The subject has an active pass.',
      },
      editTip: 'Edit subject',
      removeTip: 'Are you sure you want to delete subject?',
      actionsTips: {
        removeItem: "Remove {0}",
      },
    },
    newObjectList: {
      headers: {
        name: {
          text: "Name",
          sortable: true,
        },
        organization: {
          text: "Organization",
          sortable: false,
        },
        ready: {
          text: "Ready",
          sortable: false,
        },
      },
    },
    dialogList: {
      headers: {
        name: {
          text: "Name",
          sortable: true,
        },
        organization: {
          text: "Organization",
          sortable: false,
        },
        ref_security_list: {
          text: "Security list",
          sortable: true,
        },
        active_cards_count: {
          text: "Card",
          sortable: true,
        },
      },
    },
    cardItem: {
      headers: {
        ref_card_type: {
          text: "Type",
          sortable: true,
        },
        code: {
          text: "Code",
          sortable: true,
        },
        ref_format: {
          text: "Format",
          sortable: true,
        },
        ref_status: {
          text: "Status",
          sortable: false,
        },
        actions: {
          sortable: false,
        }
      },
      createBtn: 'Create card',
      permitList: 'Permit list',
      removeTip: 'Are you sure you want to delete card?',
      actionsTips: {
        removeItem: "Remove {0}",
      }
    },
    permitItem: {
      headers: {
        name: {
          text: 'Name',
          sortable: true,
        },
        validity: {
          text: "Validity",
          sortable: false,
        },
        levelPermit: {
          text: "Level permits",
          sortable: false,
        },
        actions: {
          sortable: false,
        },
      },
      remove: {
        title: 'Remove permit',
        confirm: 'Are you sure you want to delete permit?',
      },
      removeActive: {
        title: 'Remove permit',
        confirm: 'Are you sure you want to delete activeted permit?',
      },
      levelPermit: "Level permits",
      createBtn: "Create permit",
      removeTip: "Are you sure you want to delete permit?",
      actionsTips: {
        removeItem: "Remove {0}"
      },
      format: "MM.DD.YYYY",
    },
    violationItem: {
      headers: {
        type: {
          text: "Type",
          sortable: false,
        },
        violation_time: {
          text: "Time",
          sortable: true,
        },
        status: {
          text: "Status",
          sortable: false,
        },
        actions: {
          sortable: false,
        }
      },
      open: "Open",
      closed: "Closed",
      close: {
        title: 'Close violation',
        confirm: 'Are you sure you want to close violation?',
      },
      createBtn: "Create violation",
      closeTip: 'Are you sure you want to close violation?',
      actionsTips: {
        closeViolation: "Close violation {0}",
      },
      timeFormat: 'MM/DD/YYYY HH:mm',
    },
  },
};
