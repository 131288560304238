export default {
  'error-messages': {
    required: "Value is required",
    maxLength: "Length must be less than {0} characters",
    numeric: 'Value can be only numeric',
    alpha: 'The name must consist of Latin characters only',
    cyrillic: 'The name must consist only of cyrillic characters',
    alphaAndNumber:'The name must begin only with Latin characters and may contain numbers',
    certainSymbols: "You can enter in upper and lower case: ABEKMHOPCTYX (еn), АВЕКМНОРСТУХ (ru) and numbers",
    validJson: "Invalid JSON structure",
  }
}
