import PermitPersonCardTranslation from './ru/permit-person-card'
import PermitVehicleCardTranslation from './ru/permit-vehicle-card'
import ControllerBoardTranslation from './ru/controller-board'
import VideoStreamTranslation from './ru/video-stream'
import VerificationPanelTranslation from './ru/verification-panel'
import CommonTranslation from './ru/common'
import PassageAccessQueueTranslation from './ru/passage-access-queue'
import CardCardTranslation from './ru/card-card'
import CheckPointCardTranslation from './ru/check-point-card'
import LevelPermitCardTranslation from './ru/level-permit-card'
import PassOfficeTranslation from './ru/pass-office'
import ComponentsLocales from '../components/locales/ru'
import ErrorMessagesLocale from "./ru/error-messages";

export default Object.assign({},
  PermitPersonCardTranslation,
  PermitVehicleCardTranslation,
  ControllerBoardTranslation,
  VideoStreamTranslation,
  VerificationPanelTranslation,
  CommonTranslation,
  ComponentsLocales,
  PassageAccessQueueTranslation,
  CardCardTranslation,
  CheckPointCardTranslation,
  LevelPermitCardTranslation,
  ErrorMessagesLocale,
  PassOfficeTranslation
)
